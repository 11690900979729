<template>
  <v-container v-if="!isLoading" fluid class="bg-main" v-scroll="onScroll">
    <v-row>
      <v-col>
        <v-breadcrumbs class="px-0 pb-0" :items="computedItem">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col class="mb-2" cols="auto" xxl="8" xl="8" lg="8" md="8" sm="8">
        <v-card>
          <v-card-text>
            <p id="Detail" ref="Detail" class="text-h5 font-weight-bold">
              {{ products.product_name }}
            </p>

            <v-chip class="text-caption text-primary bg-tertiary-container my-2">
              {{ products.product_category.productcategory_name }}
            </v-chip>

            <div class="d-inline-block mx-2">
              <v-icon size="medium" color="grey" icon="mdi-map-marker"></v-icon>
              <p class="d-inline-block text-body-2 text-grey">
                {{
                  products.product_location == null
                    ? "Tidak ada lokasi"
                    : products.product_location.productlocation_name
                }}
              </p>
            </div>

            <div class="d-inline-block mx-2">
              <v-icon size="medium" color="grey" icon="mdi-calendar-blank"></v-icon>
              <p class="d-inline-block text-body-2 text-grey">
                {{ convertDate(products.event_start) }} -
                {{ convertDate(products.event_end) }}
              </p>
            </div>

            <div class="d-inline-block mx-2">
              <v-icon size="medium" color="grey" icon="mdi-clock-time-three"></v-icon>
              <p class="d-inline-block text-body-2 text-grey">
                {{ convertHour(products.event_start) }} -
                {{ convertHour(products.event_end) }}
              </p>
            </div>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col :cols="products.product_details.length > 1 ? 8 : 12">
                <v-card max-height="326">
                  <v-img @contextmenu.prevent :class="{
                    bnw:
                      products.is_upcoming ||
                      (products.quantity <= 0 && products.is_limited),
                  }" :src="products.product_details.length
                    ? products.product_details[0].productdetail_image_url
                    : this.default_image
                    " :lazy-src="products.product_details.length
                      ? products.product_details[0].productdetail_image_url
                      : this.default_image
                      " max-height="326" aspect-ratio="16/9">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-if="products.product_details.length > 1" cols="4">
                <v-row>
                  <v-col>
                    <v-card max-height="150">
                      <v-img @contextmenu.prevent :class="{
                        bnw: products.is_upcoming || products.quantity <= 0,
                      }" :src="products.product_details.length
                        ? products.product_details[1]
                          .productdetail_image_url
                        : this.default_image
                        " :lazy-src="products.product_details.length
                          ? products.product_details[1]
                            .productdetail_image_url
                          : this.default_image
                          " max-height="150">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="products.product_details[2]">
                  <v-col>
                    <v-card max-height="150" v-on="products.product_details.length > 3
                      ? {
                        click: () =>
                          showMoreImages(products.product_details),
                      }
                      : {}
                      ">
                      <v-img @contextmenu.prevent :class="{
                        bnw: products.is_upcoming || products.quantity <= 0,
                      }" :src="products.product_details.length
                        ? products.product_details[2]
                          .productdetail_image_url
                        : this.default_image
                        " :lazy-src="products.product_details.length
                          ? products.product_details[2]
                            .productdetail_image_url
                          : this.default_image
                          " max-height="150">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                        <v-row v-if="products.product_details.length > 3"
                          class="fill-height ma-0 d-flex justify-center align-center"
                          :style="{ background: 'rgba(93, 91, 92, 0.34)' }">
                          <span class="text-center text-white font-weight-bold">
                            Lihat Lebih Banyak
                          </span>
                        </v-row>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
        <v-card class="px-3">
          <v-card-text class="text-body-1 font-weight-bold pb-0 pl-0">Mulai dari</v-card-text>
          <v-card-text class="text-secondaryVariant font-weight-bold text-h5 pl-0 pt-0">
            {{ numberToRupiah(products.price_min) }}
          </v-card-text>
          <v-btn v-if="products.is_expired" class="text-none" color="error" block>
            Penjualan Berakhir
          </v-btn>

          <v-btn v-else-if="products.quantity > 0 || products.is_limited == false" @click="scroll('Kategori Tiket')"
            class="text-none" block color="primary">Cari Tiket</v-btn>

          <v-btn v-else class="text-none" color="error" block>
            Habis Terjual
          </v-btn>
          <v-divider class="my-2" thickness="2"></v-divider>
          <v-card-text class="font-weight-bold text-body-2 py-0 pl-0">Penyelenggara:</v-card-text>
          <v-card-text class="text-primary text-body-1 font-weight-bold pt-0 pl-0">
            <v-btn class="text-none px-0" variant="text"
              :to="`/store/${products.store.user_id}/${products.store.store_id}`">
              {{ products.store.store_name }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <div class="fixed mr-4 mb-4">
        <v-btn @click="scroll('Tab')" icon color="white">
          <v-icon>mdi-arrow-up-bold</v-icon>
          <v-tooltip content-class="bg-grey" activator="parent" location="top">BACK TO TOP</v-tooltip>
        </v-btn>
      </div>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div ref="Tab">
          <v-tabs class="my-4" v-model="tab" color="primary" grow bg-color="white">
            <v-tab v-for="(page, i) in pages" :key="i" :value="page" @click="scroll(page)" class="text-none">
              {{ page }}
            </v-tab>
          </v-tabs>
        </div>

        <div class="my-4 mb-6" ref="Deskripsi">
          <v-card class="py-4 px-6">
            <p class="mb-2 pl-6 text-h6 font-weight-bold">Deskripsi</p>

            <p class="text-justify pl-6" style="white-space: pre-line">
              {{ products.product_description }}
            </p>
          </v-card>
        </div>

        <v-row>
          <v-col cols="12" xxl="8" xl="8" lg="8" md="8">
            <div class="my-4 mb-6" ref="Kategori Tiket">
              <v-card class="py-4 px-6">
                <p class="mb-2 pl-6 text-h6 font-weight-bold">Kategori Tiket</p>

                <v-card elevation="4" class="mb-2" v-for="type in limitProductTypes(products.product_types)"
                  :key="type.producttype_id">
                  <v-card-title class="text-subtitle-1 font-weight-bold d-flex">
                    <p class="me-auto">
                      {{ type.producttype_name }}
                    </p>
                    <v-icon v-if="isLogin" @click="
                      type.is_bookmark
                        ? removeBookmark(type.producttype_id, type)
                        : addBookmark(type)
                      " color="primary" :icon="type.is_bookmark
                        ? 'mdi-bookmark'
                        : 'mdi-bookmark-outline'
                        "></v-icon>
                  </v-card-title>
                  <v-card-text class="py-0">
                    <v-row no-gutters>
                      <v-col cols="auto" class="d-flex align-center me-2">
                        <v-icon icon="mdi-calendar-multiselect" size="small" color="black" class="me-2"></v-icon>
                        <p>{{ `${convertDate(products.event_start)} - ${convertDate(products.event_end)}` }}</p>
                      </v-col>

                      <v-col cols="auto" class="d-flex align-center">
                        <v-icon size="small" icon="mdi-clock-time-three" class="me-2"></v-icon>
                        <p>{{ `${convertHour(products.event_start)} - ${convertHour(products.event_end)}` }}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-row no-gutters>
                    <v-col cols="12" xxl="8" xl="8" lg="8" md="8" sm="8">
                      <v-card-text class="py-0">
                        <div class="d-flex align-center">
                          <v-icon icon="mdi-clock-time-five" size="small" color="black" class="me-2"></v-icon>
                          <div class="text-none text-primary px-0" variant="text">
                            Penjualan tiket berakhir pada
                            {{
                              formatDate(
                                products.product_pull_out_date,
                                "DD MMMM YYYY, HH.mm"
                              )
                            }}
                          </div>
                        </div>
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
                      <v-card-text class="py-0 text-right">
                        <v-chip label color="primary">
                          <span v-if="type.is_limited">{{
                            `Sisa Tiket: ${type.producttype_qty -
                              type.producttype_booked_qty >=
                              0
                              ? type.producttype_qty -
                              type.producttype_booked_qty
                              : 0
                            }`
                          }}</span>
                          <span v-else>Tidak terbatas</span>
                        </v-chip>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-2 my-1 border-dashed" color="black" thickness="3"></v-divider>
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-card-text class="text-h6 font-weight-bold text-left">
                        <p v-if="type.discounted_price" class="text-neutral-50 text-decoration-line-through">{{
                          numberToRupiah(type.producttype_price) }}
                        </p>
                        <p class="text-secondary-60">{{ numberToRupiah(type.discounted_price || type.producttype_price)
                          }}
                        </p>
                      </v-card-text>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col>
                      <v-card-text class="text-right" v-if="products.is_expired">
                        <v-chip label class="bg-red-darken-4 ma-2">
                          Penjualan Berakhir</v-chip>
                      </v-card-text>

                      <v-card-text class="text-right" v-else-if="
                        type.is_limited == false ||
                        type.producttype_qty - type.producttype_booked_qty > 0
                      ">
                        <div v-if="!this.cart.length ? true : checkType(type)">
                          <v-btn block @click="addToCart(type)" class="text-none" color="primary"
                            :disabled="products.is_upcoming || checkCategory()">Pilih Tiket</v-btn>
                        </div>
                        <div class="d-flex justify-space-evenly" v-else>
                          <v-icon @click="removeFromCart(type.producttype_id)" color="primary" class="mr-2"
                            size="large">mdi-trash-can</v-icon>
                          <v-btn @click="
                            manageCart(type, false);
                          toOrder();
                          " color="primary" icon="mdi-minus" size="medium" class="mx-2" :disabled="getQty(type.producttype_id) == 0
                            ? true
                            : false ||
                            getQty(type.producttype_id) <=
                            type.producttype_minimum_order
                            " rounded="lg">
                          </v-btn>
                          {{ getQty(type.producttype_id) }}
                          <v-btn @click="
                            manageCart(type, true);
                          toOrder();
                          " color="primary" icon="mdi-plus" size="medium" class="mx-2" :disabled="(type.is_limited &&
                            (type.producttype_qty -
                              type.producttype_booked_qty <
                              0 ||
                              type.producttype_qty -
                              type.producttype_booked_qty ===
                              getQty(type.producttype_id) ||
                              type.producttype_maximum_order == 0)) ||
                            getQty(type.producttype_id) >=
                            type.producttype_maximum_order
                            " rounded="lg">
                          </v-btn>
                        </div>
                      </v-card-text>

                      <v-card-text class="text-right" v-else>
                        <v-chip label class="bg-red-darken-4 ma-2">
                          Habis Terjual</v-chip>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn class="mt-5" block v-if="products.product_types.length > limitTicket" @click="limitTicket += 5"
                  color="orange-darken-3" variant="tonal">
                  Lihat Tiket Lainnya
                </v-btn>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" xxl="4" xl="4" lg="4" md="4">
            <div class="sticky pt-3">
              <CartDetail :category="products.product_category.productcategory_id" />
            </div>
          </v-col>
        </v-row>

        <div class="my-4 mb-6" ref="Lokasi">
          <v-card class="py-6 px-6">
            <p class="mb-2 pl-6 text-h6 font-weight-bold">Lokasi</p>

            <v-card class="mb-4" elevation="4">
              <v-card-text>
                {{ products.product_location.productlocation_address }}
              </v-card-text>
              <v-sheet class="mx-4" :height="300" width="auto" rounded id="map"></v-sheet>
              <v-btn v-if="this.center" @click="openGoogleMap" class="mx-4 my-3 text-none" prepend-icon="mdi-map-marker"
                color="primaryVariant">Buka Maps</v-btn>
            </v-card>
          </v-card>
        </div>

        <div class="my-4 mb-3" ref="Informasi Lainnya">
          <v-card class="py-6 px-6">
            <p class="mb-2 pl-6 text-h6 font-weight-bold">Informasi Lainnya</p>

            <v-tabs class="my-4" v-model="tabInfo" color="primary" grow bg-color="white">
              <v-tab class="text-none" value="one">Penukaran Tiket</v-tab>
              <v-tab class="text-none" value="two">Syarat dan Ketentuan</v-tab>
            </v-tabs>

            <v-window v-model="tabInfo">
              <v-window-item value="one">
                <p class="text-justify" style="white-space: pre-line">
                  {{ products.product_redeem }}
                </p>
              </v-window-item>

              <v-window-item value="two">
                <p class="text-justify" style="white-space: pre-line">
                  {{ products.product_toc }}
                </p>
              </v-window-item>
            </v-window>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="400">
      <v-card class="text-center" rounded="lg">
        <v-card-text>
          <span class="font-weight-bold"> Oops, Kamu Belum Login Nih! </span>
          <br />
          Yuk, masuk dulu supaya bisa beli tiket kesukaanmu!
        </v-card-text>
        <v-card-text class="px-0">
          <v-btn class="text-none" to="/login" variant="flat" color="primary" width="100">Masuk</v-btn>
        </v-card-text>
        <v-card-text class="px-0 pt-0">
          <v-btn class="text-none" color="primary" variant="text" width="100" @click="dialog = false">Nanti Saja</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>

  <v-dialog v-model="dialogImage.show" width="auto">
    <v-window show-arrows>
      <v-window-item v-for="(image, n) in dialogImage.images" :key="n">
        <v-card class="d-flex justify-center align-center">
          <v-img width="80vw" height="80vh" :src="image">
            <div class="d-flex flex-row-reverse">
              <v-btn icon="mdi-close" @click="dialogImage.show = false">
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </v-window-item>
    </v-window>
  </v-dialog>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import CartDetail from "@/components/CartDetail.vue";
import { mapActions, mapGetters } from "vuex";
import { CATEGORY } from "@/config/category";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { utcToLocale } from "@/utils/dateFormatter";
import Cookies from "js-cookie";
import { numberToRupiah } from "@/utils/numberFormatter";

export default {
  name: "ConcertDetail",

  props: ["products", "isLoading", "category"],

  components: {
    CartDetail,
  },

  computed: {
    ...mapGetters(["cart"]),
    isLogin() {
      return Cookies.get("auth_token") ? true : false;
    },

    computedItem() {
      const data = {};
      let new_items = this.items;
      data["title"] = this.products.product_name;
      data["disabled"] = true;
      data["href"] = "/";
      new_items.push(data);
      return new_items;
    },
  },

  async created() {
    this.emptyCart();

    this.cart.filter((cart) => cart.product_id === this.$route.params.id);
  },

  mounted() {
    if (this.products) {
      this.createMap();
    }

    const carts = JSON.parse(localStorage.getItem("previousCart"));

    if (carts) {
      this.$store.state.cart = carts;
      localStorage.removeItem("previousCart");
    }
  },

  data() {
    return {
      dialog: false,
      dialogImage: {
        show: false,
        images: [],
      },
      offsetTop: 0,
      width: 1000,
      limitTicket: 5,
      aspectRatio: 16 / 9,
      default_image: null,
      min_price: 0,
      items: [
        {
          title: "Beranda",
          disabled: false,
          href: "/",
        },
        {
          title: "Event",
          disabled: true,
          href: "/",
        },
      ],
      pages: [
        "Deskripsi",
        "Kategori Tiket",
        "Lokasi",
        // "Ulasan",
        "Informasi Lainnya",
      ],
      types: [],
      tab: null,
      tabInfo: null,
      selectedType: "Semua",
      loading: false,
      location: "",
      access_token:
        "pk.eyJ1Ijoia2lrYXp1cnUiLCJhIjoiY2xmMjVyNDY0MGY5bTNxcDhkZ2dteXNsbCJ9.pLScRnkDfCI5eOizofKi-Q",
      center: null,
      radius: this.products
        ? this.products.product_location.productlocation_range
        : null,
      transportaion: [
        { title: "Mobil Pribadi", icon: "mdi-car" },
        { title: "Berjalan Kaki", icon: "mdi-walk" },
        { title: "Kendaraan Umum", icon: "mdi-bus" },
        { title: "Sepeda motor", icon: "mdi-motorbike" },
      ],
      snackbar: {
        status: false,
        text: "",
      },
    };
  },

  methods: {
    numberToRupiah(number) {
      return numberToRupiah(number);
    },
    showMoreImages(detailProduct) {
      const images = detailProduct.map(
        (detail) => detail.productdetail_image_url
      );
      this.dialogImage.images = images;
      this.dialogImage.show = true;
    },
    limitProductTypes(producTypes) {
      return producTypes.slice(0, this.limitTicket);
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.access_token;

        let coord = [];
        coord.push(this.products.product_location.productlocation_long);
        coord.push(this.products.product_location.productlocation_lat);

        this.center = coord;

        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v12",
          center: this.center,
          zoom: 13,
          cooperativeGestures: true
        });

        map.on("load", () => {
          map.addSource("radius", {
            type: "geojson",
            data: this.createGeoJSONCircle(this.center, this.radius / 1000),
          });

          const marker = new mapboxgl.Marker();
          marker.setLngLat(this.center).addTo(map);

          map.addLayer({
            id: "radius-layer",
            type: "fill",
            source: "radius",
            layout: {},
            paint: {
              "fill-color": "blue",
              "fill-opacity": 0.6,
            },
          });
        });
      } catch (err) {
        console.log("map error", err);
      }
    },

    createGeoJSONCircle(center, radiusInKm, points = 64) {
      var coords = {
        latitude: center[1],
        longitude: center[0],
      };

      var km = radiusInKm;
      var ret = [];
      var distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      var distanceY = km / 110.574;
      var theta, x, y;
      for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [ret],
            },
          },
        ],
      };
    },

    openGoogleMap() {
      const urlSuffix = this.center[1] + ", " + this.center[0];
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    onScroll(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },

    scroll(id) {
      this.$nextTick(() => {
        this.$refs[id].scrollIntoView({ behavior: "smooth" });
      });
    },

    ...mapActions([
      "addItemToCart",
      "addQty",
      "reduceQty",
      "emptyCart",
      "removeItemFromCart",
    ]),

    checkType(type) {
      if (this.cart.length) {
        const data = this.cart[0].products[0].product_types.find(
          (types) => types.producttype_id === type.producttype_id
        );
        if (data) {
          return false;
        } else {
          return true;
        }
      }
    },

    checkCategory() {
      if (this.cart.length) {
        if (this.cart[0].products[0].product_types.length) {
          return this.cart[0].products[0].product_category
            .productcategory_id === CATEGORY.RACE
            ? true
            : false;
        }
      }
    },

    addToCart(type) {
      if (this.isLogin) {
        if (
          type.is_limited &&
          type.producttype_minimum_order >
          type.producttype_qty - type.producttype_booked_qty
        ) {
          this.showSnackbar(
            "Jumlah tiket kurang dari minimal order, silahkan hubungi store"
          );
        } else {
          let product_types = [];
          product_types.push({ ...type, qty: type.producttype_minimum_order });
          let orders = {
            store_id: this.products.store.store_id,
            store_name: this.products.store.store_name,
            store_image: this.products.store.store_image,
            products: [
              {
                product_id: this.products.product_id,
                product_name: this.products.product_name,
                product_location: this.products.product_location,
                product_types: product_types,
                product_image: this.products.product_details.length
                  ? this.products.product_details[0].productdetail_image_url
                  : this.default_image,
                product_category: this.products.product_category,
                product_slug: this.products.product_slug,
              },
            ],
          };
          this.$store.dispatch("addItemToCart", orders);
        }
      } else {
        this.dialog = true;
      }
    },

    manageCart(type, add) {
      if (add) {
        this.$store.dispatch("addQty", type.producttype_id);
      } else {
        this.$store.dispatch("reduceQty", type.producttype_id);
      }
    },

    removeFromCart(id) {
      this.$store.dispatch("removeItemFromCart", id);
    },

    toOrder() {
      let product_types = [];
      product_types = this.cart
        .filter((cart) => cart.product_id === this.products.product_id)
        .map((types) => ({
          producttype_id: types.producttype_id,
          producttype_name: types.producttype_name,
          producttype_price: types.producttype_price,
          qty: types.qty,
        }));
      let orders = {
        store_id: this.products.store.store_id,
        store_name: this.products.store.store_name,
        store_image: this.products.store.store_image,
        products: [
          {
            product_id: this.products.product_id,
            product_name: this.products.product_name,
            product_location: this.products.product_location,
            product_types: product_types,
            product_image: this.products.product_details.length
              ? this.products.image
              : this.default_image,
          },
        ],
      };
      this.$store.dispatch("addOrders", orders);
    },

    formatDate(isoDate, format) {
      return utcToLocale(isoDate, format);
    },

    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD MMM YYYY");
    },

    convertHour(isoDate) {
      return utcToLocale(isoDate, "HH:mm");
    },

    getQty(id) {
      if (this.cart.length) {
        const data = this.cart[0].products[0].product_types.find(
          (types) => types.producttype_id === id
        );
        return data.qty;
      } else {
        return 0;
      }
    },

    addBookmark(productType) {
      this.axios
        .post("product/bookmark/add", {
          user_id: this.$store.state.user.user_id,
          product_id: this.products?.product_id,
          producttype_id: productType.producttype_id,
        })
        .then((response) => {
          if (response.data.response.bookmark_id) {
            productType.is_bookmark = true;
            productType.bookmarked_by.push({
              bookmark_id: parseInt(response.data.response.bookmark_id),
              user_id: response.data.response.user_id,
              product_id: response.data.response.product_id,
              producttype_id: response.data.response.producttype_id,
            });
            this.showSnackbar("Tiket berhasil disimpan");
          }
        })
        .catch((error) => {
          if (error.response?.status) {
            switch (error.response.status) {
              case 427:
                this.showSnackbar("Tiket ini sudah ada dalam menu disimpan!");
                break;
            }
          }
        });
    },
    removeBookmark(producttype_id, type) {
      const userId = this.$store.state.user.user_id;
      const bookmark = type.bookmarked_by.find(
        (bookmark) => bookmark.user_id === parseInt(userId)
      );
      const bookmark_id = bookmark.bookmark_id;
      this.axios
        .delete("product/bookmark/delete", {
          data: {
            user_id: userId,
            producttype_id: producttype_id,
            bookmark_id: bookmark_id,
          },
        })
        .then(() => {
          this.products.product_types.map((type) => {
            if (type.producttype_id === producttype_id)
              type.is_bookmark = false;
            return type;
          });
          this.showSnackbar("Tiket berhasil dihapus");
        })
        .catch(() => {
          this.showSnackbar("Tiket gagal dihapus!");
        });
    },
    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
};
</script>

<style scoped>
* {
  scroll-margin-top: 100px;
}

.fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.bnw {
  filter: grayscale(100%);
}

.protected-image {
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
}
</style>
