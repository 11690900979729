<template>
  <v-card>
    <v-container>
      <v-row dense justify="center">
        <v-col cols="12">
          <p class="text-subtitle-1 text-primary font-weight-bold">Pesananmu</p>
        </v-col>

        <v-col v-if="!cart.length || !cart[0].products[0].product_types.length" cols="12">
          <p class="text-subtitle-2 text-black font-weight-regular">
            Tiket yang kamu pilih akan tampil disini
          </p>
        </v-col>

        <template v-else-if="cart.length" v-for="(item, i) in computedCart" :key="i">
          <v-col cols="6">
            <p class="text-subtitle-1 text-black font-weight-bold">
              {{ item.producttype_name }}
            </p>
          </v-col>

          <v-col cols="6">
            <p class="text-subtitle-1 text-black font-weight-bold text-right">
              {{ item.qty }} tiket
            </p>
          </v-col>
        </template>

        <v-col v-if="cart.length ? cart[0].products[0].product_types.length : false" cols="6">
          <p class="text-subtitle-2">Subtotal</p>
        </v-col>

        <v-col v-if="cart.length ? cart[0].products[0].product_types.length : false" cols="6">
          <p class="text-subtitle-1 text-black font-weight-bold text-right">
            Rp. {{ totalPrice.toLocaleString() }}
          </p>
        </v-col>

        <v-divider thickness="2"></v-divider>

        <v-col cols="6" align-self="center" class="text-left">
          <p class="text-subtitle-2">Total Tiket</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="cart.length ? cart[0].products[0].product_types.length : false" cols="6" align-self="center"
          class="text-right">
          <p class="text-subtitle-1 font-weight-bold">
            Rp. {{ totalPrice.toLocaleString() }}
          </p>
        </v-col>
        <v-col cols="12" align-self="center" class="pa-0 mt-2">
          <div v-if="cart[0]?.products[0] && totalQty > maxOrderTicket" class="rounded-lg text-center bg-red pa-2"
            :style="{ width: '100%' }">
            Maksimal Pembelian 20 Tiket
          </div>
          <v-btn v-else class="text-none" @click="buyNow" rounded="lg" block color="primary" :loading="isBuy" :disabled="isBuy ||
            isCart ||
            !isLogin ||
            !cart.length ||
            !cart[0].products[0].product_types.length
            ">
            Beli Tiket
          </v-btn>
        </v-col>
        <v-col cols="12" align-self="center" class="pa-0 mt-2 mb-2">
          <v-btn v-if="category !== CATEGORY.RACE" class="text-none" @click="placeOrder" rounded="lg" variant="outlined"
            block color="primary" :loading="isCart" :disabled="isBuy ||
              isCart ||
              !cart.length ||
              !cart[0].products[0].product_types.length ||
              !isLogin
              ">
            Tambah ke Keranjang
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import limitation from "@/config/limitation";
import Cookies from "js-cookie";
import { CATEGORY } from "@/config/category";

export default {
  name: "CartDetail",

  props: ["category"],

  data() {
    return {
      isBuy: false,
      isCart: false,
      orderPlaced: false,
      dialog: false,

      snackbar: {
        status: false,
        text: "",
      },
      maxOrderTicket: limitation.maxOrderTicket,
      CATEGORY: CATEGORY,
    };
  },

  computed: {
    ...mapGetters(["cart"]),

    isLogin() {
      return Cookies.get("auth_token") ? true : false;
    },

    totalPrice() {
      return this.cart[0].products[0].product_types.reduce(
        (a, b) => a + b.qty * (b.discounted_price ?? b.producttype_price),
        0
      );
    },
    totalQty() {
      return this.cart[0].products[0].product_types.reduce(
        (a, b) => a + b.qty,
        0
      );
    },

    computedCart() {
      return this.cart[0].products[0].product_types;
    },
  },
  methods: {
    ...mapActions(["removeItemFromCart", "addQty", "reduceQty", "emptyCart"]),
    placeOrder() {
      this.isCart = true;
      const product = this.cart[0].products[0];
      let obj = {
        product_id: product.product_id,
        user_id: this.$store.state.user.user_id,
        product_types: [],
      };
      product.product_types.forEach((element) => {
        const obj_types = {};
        obj_types["producttype_id"] = element.producttype_id;
        obj_types["qty"] = element.qty;
        obj.product_types.push(obj_types);
      });

      this.axios
        .put(`cart/add`, obj)
        .then(() => {
          this.showSnackbar(
            `Berhasil menambahkan produk ${product.product_name} ke dalam keranjang!`
          );
          this.$store.state.countCartChange = true;
          this.emptyCart();
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 471: {
                this.showSnackbar(
                  "Jumlah item melebihi maksimal order, pastikan tiket anda di keranjang"
                );
                break;
              }
              case 472: {
                this.showSnackbar("Jumlah item melebihi stok");
                break;
              }
            }
          }
        })
        .finally(() => {
          this.isCart = false;
        });
    },

    buyNow() {
      this.isBuy = true;
      setTimeout(() => {
        if (this.isLogin) {
          this.isBuy = false;
          this.$router.push({ name: 'purchase' });
        } else {
          this.isBuy = false;
        }
      }, 2000);
    },

    subtotalPrice(price, qty) {
      return qty * price;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
};
</script>

<style scoped></style>
